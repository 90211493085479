

body.homepage {
    overflow-x: hidden;
    .bgcolor{
        background-color: $color1;
    }
    .diagbg-reverse{
        background-image: linear-gradient(to top left, $color1 50%, $white 50.1%);
    }
    .diagbg{
        background-image: linear-gradient(to bottom right, $color1 50%, $white 50.1%);
    }
}
body.agenda {
    .bgcolor{
        background-color: $color2;
    }
    .diagbg{
        background-image: linear-gradient(to bottom right, $color2 50%, $white 50.1%);
    }
}
body.repertoire {
    .bgcolor{
        background-color: $color3;
    }
    .diagbg{
        background-image: linear-gradient(to bottom right, $color3 50%, $white 50.1%);
    }
}
body.membres {
    .bgcolor{
        background-color: $color4;
    }
    .diagbg{
        background-image: linear-gradient(to bottom right, $color4 50%, $white 50.1%);
    }
}
body.photos {
    .bgcolor{
        background-color: $color5;
    }
    .diagbg{
        background-image: linear-gradient(to bottom right, $color5 50%, $white 50.1%);
    }
}
body.contact {
    .bgcolor{
        background-color: $color6;
    }
    .diagbg{
        background-image: linear-gradient(to bottom right, $color6 50%, $white 50.1%);
    }
    .btn-primary{
        background-color: $color6;
        border: 1px solid $color6;
        &:hover{
            background-color: darken($color6, 5);
        }
        &:focus{
            border: 2px solid darken($color6, 5);
        }
    }
    .invalid-feedback{
        color: $black;
    }
}
body.joinus {
    .bgcolor{
        background-color: $color7;
    }
    .diagbg{
        background-image: linear-gradient(to bottom right, $color7 50%, $white 50.1%);
    }
}
body.event{
    .bgcolor{
        background-color: $color8;
    }
    .diagbg{
        background-image: linear-gradient(to bottom right, $color8 50%, $white 50.1%);
    }
}
body.gallery-detail{
    .bgcolor{
        background-color: $color9;
    }
    .diagbg{
        background-image: linear-gradient(to bottom right, $color9 50%, $white 50.1%);
    }
}
.diagbg, .diagbg-reverse{
    background-size: 100% 600px;
    background-repeat: no-repeat;
}
.diagbg{
    background-position: top;
}
.diagbg-reverse{
    background-position: bottom;
}
.btn{
    border-radius: 0;
    min-width: 250px;
}
.btn-primary{
    color: $white;
    &:hover, &:active, &:visited{
        color: $white;
    }
}
.btn-outline-secondary{
    color: $black;
    border-color: $black;
    background-color: $white;
    &:hover, &:active, &:visited{
        color: $black;
        background-color: $gray-100;
    }
}
h1, h2, h3, h4, h5, h6{
    margin-bottom: 1rem;
}

p a{
    color: black;
    text-decoration: underline;
    &:hover{
        color: lighten(black, 30);
    }
}

// Menu //

nav.navbar{
    .navbar-collapse{
        .nav-link{
            color:$white;
            display: block;
            height: 50px;
            margin: 0;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 1.2em;
            line-height: 35px;
            &:hover, &:visited, &:active{
                color:$white;
                text-decoration: underline;
            }
            &.active{
                color: $white;
                border-bottom: 1px solid $white;
                border-top: 1px solid $white;
            }
        }
    }
    .navbar-brand{
        display: inline-block;
        background-image: url('../images/main-hp-logo.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        width: 100px;
        height: 100px;
        text-indent: -2000px;
        margin: 0 20px 0 40px;
        padding-top: 10px;
    }
    .navbar-text{
        a{
            font-size: 1.5em;
            color: $white;
            text-decoration: none;
            &:hover{
                color: $white;
                text-decoration: underline;
            }
            padding-left: 10px;
        }
    }
    .navbar-toggler{
        margin-right: 20px;
    }

    border-bottom: 1px solid $white;
}

@include media-breakpoint-up(sm) {
    nav.navbar{
        padding: 0 20px 0 0;
        .navbar-collapse{
            .nav-link{
                display: inline-block;
                height: 120px;
                padding-left: 20px;
                padding-right: 20px;
                line-height: 100px;
                &.active{
                    color: $white;
                    border-bottom: 3px solid $white;
                    border-top-style: none;
                }
            }
        }
    }
}


// Pages //

.pageintro{
    color: $white;

    p{
        font-size: 1.2em;
    }

    a{
        color: $white;
        &:hover{
            color: darken($white, 5);
        }
    }
}

// Homepage //

.presentation{
    color: $white;
    text-align: center;
    padding: 4rem 0;
}
.homepage .contact{
    color: $white;
    padding: 4rem 0;
    .btn{
        margin-bottom: 15px;
    }
}
.hp-images-container{
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.hp-images{
    text-align: center;
    width: 3000em;
    margin:0 0 0 50%;
    padding: 0;
    transform: translateX(-50%);
}

// Concerts // 

.large-concert-preview{
    font-size: 1.3em;
    a{
        color: $black;
        text-decoration: none;
        &:hover, &:visited, &:active{
            color: $black;
        }
        &:hover{
            .name{
                text-decoration: underline;
            }
        }
    }
    .date{
        margin-bottom: 0.2rem;
    }
    .name{
        font-family: $headings-font-family;
    }
    .place{
        font-size: 0.8em;
    }
}

.concert-preview{
    a{
        color: $black;
        text-decoration: none;
        &:hover, &:visited, &:active{
            color: $black;
        }
        &:hover{
            .name{
                text-decoration: underline;
            }
        }
    }
    .date{
        margin-bottom: 0.2rem;
    }
    .name{
        font-family: $headings-font-family;
    }
    .place{
        font-size: 0.8rem;
    }
}

.event .event-content{
    a{
        color: $black;
        text-decoration: none;
        &:hover, &:visited, &:active{
            color: $black;
        }
        &:hover{
            text-decoration: underline;
        }
    }
}
.season{
    font-family: $font-family-sans-serif;
    font-size: 1em;
}

// Membres // 

.membres{
    .chef{
        color: $white;
    }
    .legend{
        font-size: 1.5em;
        text-align: right;
        span {
            padding-left: 20px;
        }
    }

    .membre{
        position: relative;
        h3 {
            color: $white;
            position: absolute;
            top: 10px;
            left: 30px;
            z-index: 100;
        }
        .picture{
            position: relative;
            z-index: 90;
        }
        .voice{
            position: absolute;
            top: 10px;
            right: 30px;
            z-index: 95;
            font-size: 1.5em;
            color: $white;
        }
    }
    .shader {
        position: absolute;
        top: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(black, transparent);
        opacity: 0.6;
        z-index: 95;
      }
}

// Photo galleries //

.galleries{
    a{
        color: $black;
        text-decoration: none;
        &:hover, &:visited, &:active{
            color: $black;
        }
        &:hover{
            .name{
                text-decoration: underline;
            }
        }
    }
    h2{
        font-size: 1.2rem;
    }
    .dates{
        font-size: 0.8rem;
    }
}
.gallery-detail{
    .pageintro{
        .dates{
            font-family: $headings-font-family;
        }
    }
}


// Footer //

footer{
    border-top: 1px solid $white;
    color: $white;
    a{
        color: $white;
        font-size: 1.5em;
        padding-left: 10px;
        &:hover, &:visited, &:active{
            color:$white;
        }
    }
}